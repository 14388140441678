<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col sm="6" cols="12" md="6" lg="3">
        <router-link tag="a" to="/feedback/general-feedback">
          <material-stats-card
            color="#156a59"
            icon="mdi-briefcase-search-outline"
            title="General feedback"
            value
          />
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3">
        <router-link tag="a" to="/feedback/complaints-overview">
          <material-stats-card
            color="#156a59"
            icon="mdi-account-supervisor-circle"
            title="Complaints"
            value
          />
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.v-card--material-stats:hover {
  border-top: 4px solid #156a59;
}
</style>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {}
  }
};
</script>
